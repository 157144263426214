import { makeUseAxios } from "axios-hooks";

const { default: Axios } = require("axios");

export const BASE_URL = "https://admin.crm.asme.org.sg";
// export const BASE_URL = "https://dev.admin.crm.asme.org.sg";
// export const BASE_URL = "http://localhost:1337";
export const CLIENT_SITE_URL = "https://members.asme.org.sg";
export const ASME_SITE_BASE_URL = "https://admin.asme.org.sg"; // always same
export const ASME_SITE_URL = "https://crm.asme.org.sg";

export const axios = Axios.create({
  baseURL: BASE_URL
});

export const usePublicAxios = makeUseAxios({
  axios: axios
});

export const authedAxios = Axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

authedAxios.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("member_token")}`;

export const useAuthedAxios = makeUseAxios({
  axios: authedAxios
});
